import { FlexCell, FlexRow } from "@/common/StyledComponents";
import { formattedCurrency } from "@/utils";
import { Divider, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { selectProjectCurrency, selectTotals } from "./actualSlice";
import { useSelector } from "react-redux";
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const TotalRow = ({
    rowLabelComponent, 
    estimated, running, runningVariance, actual, actualVariance, color, fontWeight,
    estimatedTooltip, runningTooltip, runningVarianceTooltip, actualTooltip, actualVarianceTooltip
}) => {
    return (
        <>
            <FlexRow style={{height: 40}}>
                <FlexCell minWidth={115}>{rowLabelComponent}</FlexCell>
                <TotalFlexCell total={estimated} tooltip={estimatedTooltip} fontWeight={fontWeight} color={color}/>
                <TotalFlexCell total={actual} tooltip={actualTooltip} fontWeight={fontWeight} color={color} />
                <div style={{paddingRight: 40}}>
                    <TotalFlexCell total={actualVariance} tooltip={actualVarianceTooltip} fontWeight={fontWeight} color={color} />
                </div>
                <Divider orientation="vertical"/>
                <div style={{paddingLeft: 40}}>
                    <TotalFlexCell total={running} tooltip={runningTooltip} fontWeight={fontWeight} color={color}/>
                </div>
                <TotalFlexCell total={runningVariance} tooltip={runningVarianceTooltip} fontWeight={fontWeight} color={color}/>
            </FlexRow>
            <Divider sx={{borderColor: "#aaaaaa44"}}/>
        </>
    )
}

const TotalFlexCell = ({total, color, fontWeight, tooltip}) => {
    const theme = useTheme()
    const isNumber = !isNaN(total)
    const projectCurrency = useSelector(selectProjectCurrency)
    color = total == "N/A" ? "#aaaaaaaa" : color
    return (
        <>
            <FlexCell align="right" style={{maxWidth: 140, minWidth: 150}}>
                <div style={{display: "flex", justifyContent: "right", alignItems: "center", gap: 2}} >
                    {tooltip && <Tooltip title={tooltip}>
                        <HelpRoundedIcon fontSize="10px" sx={{color: "gray"}}/>
                    </Tooltip>}
                    {<span style={{fontWeight: fontWeight ? fontWeight : 400, color: color ? color : (total < 0 ? "red" : theme.palette.text.primary)}}>
                        {isNumber ? formattedCurrency(total, projectCurrency, false, 2) : total}
                    </span>}
                </div>
            </FlexCell>
        </>
    )
}

const ProfitNumber = ({amount, margin}) => {
    const projectCurrency = useSelector(selectProjectCurrency)
    const percentageColor = (!isNaN(amount) && amount < 0) ? "red" : "gray"
    const amountColor = (!isNaN(amount) && amount < 0) && "red"
    return (
        <Grid container justifyContent={"right"} alignItems={"center"}>
            <Grid item sx={{color: percentageColor, paddingRight: 0.6, fontSize: 10}}>{`(${(margin * 100).toFixed(1)}%)`}</Grid>
            <Grid item sx={{color: amountColor}}>{formattedCurrency(amount, projectCurrency, false, 2)}</Grid>
        </Grid>
    )
}

export default function ActualTotals(){
    const totals = useSelector(selectTotals)

    /*

    NOTE: the calculations made here are not to replace backend getters, but for exceptionally agile development / testing purposes

    */
   
    const gross_profit_margin = totals.quoted ? totals.gross_profit / totals.quoted : 0
    const net_profit_margin = totals.quoted ? totals.net_profit / totals.quoted : 0

    const actual_gross_profit = totals.billed - totals.hard_cost_actual
    const actual_gross_profit_margin = totals.billed ? actual_gross_profit / totals.billed : 0

    const actual_net_profit = actual_gross_profit - totals.internal_actual
    const actual_net_profit_margin = totals.billed ? actual_net_profit / totals.billed : 0

    return (
        <>
            <Paper sx={{padding: "10px 35px 25px 35px", boxShadow: "none", overflow: "auto"}}>
                <TotalRow
                    color={"gray"}
                    rowLabelComponent={<h4>Budget</h4>} estimated={"Estimated"}
                    running={"Running"} runningVariance={"Running Remaining"}
                    actual={"Actual"} actualVariance={"Actual Remaining"}
                />
                <TotalRow
                    fontWeight={600}
                    rowLabelComponent={<span style={{fontWeight: 600}}>- Total Costs</span>}
                    estimated={totals.client_budget} estimatedTooltip={"Budget Total: Total of all bids' budgets, aka the project budget. Does not include markup."}
                    running={totals.running} runningTooltip={"Running Total: Total of all actual lines' running amount"}
                    runningVariance={totals.running_variance} runningVarianceTooltip={"Budget Total - Running Total"}
                    actual={totals.actual} actualTooltip={"Actual Total: Total of actual lines’ allocation"}
                    actualVariance={totals.actual_variance} actualVarianceTooltip={"Budget Total - Actual Total"}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Hard Costs</span>}
                    estimated={totals.hard_cost_budget} estimatedTooltip={"Hard Cost Budget Total: Total of all bids' budget, but only include hard cost lines plus 80% of insurance"}
                    running={totals.hard_cost_running} runningTooltip={"Running Total: but only those associated with a hard cost line item"}
                    runningVariance={totals.hard_cost_running_variance} runningVarianceTooltip={"Hard Cost Budget Total - Hard Cost Running Total"}
                    actual={totals.hard_cost_actual} actualTooltip={"Hard Cost Actual Total: Actual Total, but only those associated with a hard cost line item"}
                    actualVariance={totals.hard_cost_actual_variance} actualVarianceTooltip={"Hard Cost Budget Total - Hard Cost Actual Total"}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Internal Costs</span>}
                    estimated={totals.internal_budget} estimatedTooltip={"Internal Budget Total: Total of all bids' budget, but only include hours lines"}
                    running={totals.internal_running} runningTooltip={"Float Scheduled: all hours added to the Float's Schedule page"}
                    runningVariance={totals.internal_running_variance} runningVarianceTooltip={"Internal Budget Total - Float Scheduled"}
                    actual={totals.internal_actual} actualTooltip={"Float Logged: hours marked logged on the Float Log Team page"}
                    actualVariance={totals.internal_actual_variance} actualVarianceTooltip={"Internal Budget Total - Float Logged"}
                />
            </Paper>
            <br/>
            <Paper sx={{padding: "10px 35px 25px 35px", boxShadow: "none", overflow: "auto"}}>
                <TotalRow
                    color={"gray"}
                    rowLabelComponent={<h4>Profitability</h4>} estimated={"Estimated"}
                    running={<span style={{color: "#aaaaaaaa"}}>Running</span>} runningVariance={<span style={{color: "#aaaaaaaa"}}>Running Remaining</span>}
                    actual={"Actual"} actualVariance={"Actual Remaining"}
                />
                <TotalRow
                    fontWeight={600}
                    rowLabelComponent={<span style={{fontWeight: 600}}>- Total Quoted</span>}
                    estimated={totals.quoted} estimatedTooltip={"Quoted Total: Total of all bids' quoted amount, aka project quoted"}
                    running={"N/A"} runningVariance={"N/A"}
                    actual={totals.billed} actualTooltip={"Bills Total: Total of bills that are marked Sent"}
                    actualVariance={totals.quoted - totals.billed} actualVarianceTooltip={"Quoted Total - Bills Total"}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Gross Profit</span>}
                    estimated={<ProfitNumber amount={totals.gross_profit} margin={gross_profit_margin} />} estimatedTooltip={"Quoted Gross Profit: Quoted Total - Hard Cost Budget Total"}
                    running={"N/A"} runningVariance={"N/A"}
                    actual={<ProfitNumber amount={actual_gross_profit} margin={actual_gross_profit_margin} />} actualTooltip={"Actual Gross Profit: Bills Total - Actual Hard Cost"}
                    actualVariance={"N/A"}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Net Profit</span>}
                    estimated={<ProfitNumber amount={totals.net_profit} margin={net_profit_margin} />} estimatedTooltip={"Quoted Net Profit: Quoted Gross Profit - Internal Budget Total"}
                    running={"N/A"} runningVariance={"N/A"}
                    actual={<ProfitNumber amount={actual_net_profit} margin={actual_net_profit_margin} />} actualVariance={"N/A"} actualTooltip={"Actual Net Profit: Actual Gross Profit - Float Logged "}
                />
            </Paper>
        </>
    )
}