import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import CircularProgress from "@mui/material/CircularProgress"

import { setToken, selectIsTokenInvalid } from "@/auth/authSlice"
import { setError } from '@/nav/navSlice'
import { InitScreen } from "@/auth/StyledComponents"

export default function Auth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const invalidToken = useSelector(selectIsTokenInvalid)
    const [ loaded, setLoaded ] = useState(false)

    useEffect(() => {
        if (invalidToken) {
            dispatch(setError({message: "Invalid login token, please login again."}))
            navigate("/logout")
        }
    }, [invalidToken])

    useEffect(() => {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            dispatch(setToken(token))
        }
        // Slight delay since updating local state will hide the InitScreen and show the login screen
        // before redux triggers the app to show your desired logged-in page
        setTimeout(() => {
            setLoaded(true)
        }, 100)
    }, [])

    return loaded ? null : (
        <InitScreen whiteBk>
            <CircularProgress color="primary" sx={{marginTop: "12px"}} />
        </InitScreen>
    )
}
