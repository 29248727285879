import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from 'styled-components'

import { Chip, useTheme } from "@mui/material";

import { request } from "@/Api";
import { formattedCurrency, getNth } from "@/utils";
import { selectDetails, selectGroup, selectLine, updateLine, selectBills } from "@/bid/bidSlice";
import { topSheetColumns } from "@/bid/TopSheetColumns";
import { RATE_TYPE_PERCENTAGE, TOPSHEET_DATE_LOCATION_IDENTIFYER_STRING, TOPSHEET_NAME_LOCATION_IDENTIFYER_STRING, TOPSHEET_SIGNATURE_LOCATION_IDENTIFYER_STRING } from "@/bid/constants";


export const PreviewContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
`

export const PreviewBackground = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: ${props => props.zoomLevel == 0 ? "60vh" : props.zoomLevel == 1 ? "80vh" : "97vh"};
    height: 90vh;
    padding: 4vh 4vh 2vh 4vh;
    margin: 2vh 0;
    background: white;
    z-index: -1;
`

export const PreviewContents = styled.div`
    position: relative;
    width: ${props => props.zoomLevel == 0 ? "60vh" : props.zoomLevel == 1 ? "80vh" : "97vh"};
    height: 90vh;
    padding: 4vh 4vh 2vh 11vh;
    margin: 2vh auto;
    transform: translateX(-3.5vh);
    overflow: auto;
    page-break-inside: avoid;
    color: black;    
    font-size: ${props => props.zoomLevel == 0 ? "1.6vh" : props.zoomLevel == 1 ? "2.6vh" : "3.2vh"};
    
    table {
        border-spacing: 0;
        width: 100%;
        font-size: ${props => props.zoomLevel == 0 ? "1.6vh" : props.zoomLevel == 1 ? "2.6vh" : "3.2vh"};
    }

    h1 {
        text-align: center;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

export const PrintPreviewContents = styled(PreviewContents)`
    width: 890px;
    height: 100%;
    margin: 20px auto;
    padding: 0em 48px 48px 48px;
    font-size: 24px;
    overflow: visible;

    table {
        font-size: 24px;
    }
`

export const TopP = styled.p`
    font-weight: ${(props)=> props.bold ? 600 : 400};
    font-family: 'Inter Tight', sans-serif;
    margin: 0;
    font-size: ${(props)=> props.size ? `${props.size}em` : "1em"}
`

export const TopSpan = styled.span`
    font-family: 'Inter Tight', sans-serif;
    margin: 0;
    font-size: ${(props)=> props.size ? `${props.size}em` : "1em"}
`

export const TopTd = styled.td`
    font-family: 'Inter Tight', sans-serif;
    min-width: ${(props)=>{return props.minWidth != null ? `${props.minWidth}em` : "3.5em"}};
    padding: ${props => props.condensed ? "0" : "0.5em 0em"}; 
    break-inside: avoid;
    font-weight: ${(props)=>props.bold ? 600 : 400};
    font-size: ${(props)=>props.size ? `${props.size}em` : "1em"};
    color: ${(props)=>props.grey ? "#aaa" : "auto"};
`

export const PageBreakRow = ({printMode, highlight, toggleBreak, isHovering, isBreaking, columnSet}) => {
    const theme = useTheme()
    return (
        <tr>
            {topSheetColumns.map((column, i)=>
                <TopTd minWidth={columnSet.includes(column) ? column.minWidth : 0} key={i} style={{borderTop: printMode ? null : `1px solid ${highlight ? theme.palette.primary.main + "66" : theme.palette.primary.main}`, fontSize: "0.5em", padding: 0}}>
                    <div style={{pageBreakAfter: "always"}}></div>
                    {!printMode && i == 0 && (
                        <Chip
                            size="small" 
                            label="Page Break" 
                            color="primary"
                            onDelete={toggleBreak} 
                            sx={{
                                width: "10vh",
                                height: "3em",
                                fontSize: "10px", 
                                position: "absolute", 
                                transform: "translate(-100%, -50%)", 
                                zIndex: 10, 
                                opacity: isHovering && !isBreaking ? 0.5 : 1
                            }}
                        />
                    )}
                </TopTd>
            )}
        </tr>
    )
}

export const LineItemRow = ({id, currency, showZero, showDecimal, visibleColumns, printMode, columnSet, lastRow, rateType, viewOnly, condensed}) => {
    const line = useSelector(state => selectLine(state, id))
    const dispatch = useDispatch()

    const [ isHovering, setIsHovering ] = useState(false) 
    
    const toggleBreak = () => {
        const newLine = {...line, topsheet_breaking: !line.topsheet_breaking}
        dispatch(updateLine(newLine))
        request.put(`/bids/${line.bid_id}/line-items/${id}`, newLine)
    }

    if (!line){
        return
    }
    if (!showZero && line.client_quoted == 0){
        return
    }
    return (
        <>
            <tr 
                onMouseOver={()=>{setIsHovering(true)}} 
                onMouseLeave={()=>{setIsHovering(false)}} 
                onClick={viewOnly ? null : toggleBreak} 
                style={{cursor: line.topsheet_breaking || viewOnly ? "default" : "pointer"}}
            >
                {topSheetColumns.map((column)=>
                    <TopTd
                        grey={!column.isTotalField}
                        key={column.field}
                        style={{color: column.color, textAlign: column.align, paddingLeft: "1em", paddingBottom: lastRow ? condensed ? "0.5em" : "1.5em" : "auto"}}
                        size={0.45}
                        minWidth={columnSet.includes(column.field) ? column.minWidth : 0} 
                        condensed={condensed}
                    >
                        {
                            visibleColumns.includes(column.field) && !column.ignoredByRateTypes.includes(rateType)?
                            <>
                                {
                                    // TODO: pass formatting to TopSheetColumns.js?
                                    ["client_rate", "client_quoted", "adjustment"].includes(column.field) ?
                                    formattedCurrency(line[column.field], currency, true, showDecimal ? 2 : 0) :
                                    column.field == "vendor_rate" ?
                                    formattedCurrency(line[column.field], line.vendor_currency, true, showDecimal ? 2 : 0) :
                                    (column.field == "units" && rateType == RATE_TYPE_PERCENTAGE) || column.field == "markup" ?
                                    `${(line[column.field] * 100).toFixed()}%` :
                                    line[column.field]
                                }
                            </>:
                            null
                        }
                    </TopTd>
                )}
            </tr>

            
            {viewOnly ? null :
                <>
                    { line.topsheet_breaking || isHovering ?
                        <PageBreakRow
                            isHovering={isHovering}
                            isBreaking={line.topsheet_breaking}
                            toggleBreak={toggleBreak}
                            printMode={printMode}
                            highlight={isHovering && !line.topsheet_breaking}
                            columnSet={columnSet}
                        />
                    : null}
                </>
            }
        </>
    )
}

export const HeaderRow = ({projectCurrency, visibleColumns, columnSet, rateType}) => {
    return (
        <>
            <tr>
                {topSheetColumns.map((column)=>
                    <TopTd
                        key={column.field}
                        style={{textAlign: column.align, paddingLeft: "1em"}}
                        size={0.45}
                        grey
                        minWidth={columnSet.includes(column.field) ? column.minWidth : 0} 
                    > 
                        {
                            visibleColumns.includes(column.field) && !column.ignoredByRateTypes.includes(rateType)?
                            <>
                                {
                                    ["client_rate", "client_quoted"].includes(column.field) ?
                                    `${column.name} (${projectCurrency})` :
                                    column.field == "units" && rateType == RATE_TYPE_PERCENTAGE ?
                                    "Capacity" :
                                    column.field == "days" ?
                                    rateType == "Percentage" ? "Days" : rateType :
                                    column.name
                                }
                            </>:
                            null
                        }
                    </TopTd>
                )}
            </tr>
        </>
    )
}

export const TopSheetSection = ({id, currency, showZero, showDecimal, printMode, columnSet, viewOnly, condensed}) => {
    const section = useSelector(state => selectGroup(state, id))
    if (!section){
        return
    }
    if (section.client_quoted == 0 && !showZero){
        return
    }
    return ( 
        <>
            <tr key={id}>
                <TopTd size={0.7} bold style={{paddingTop: condensed ? "1em" : "2em"}}>{section.name}</TopTd>
                {topSheetColumns.slice(1,-1).map((column, i)=>
                    <TopTd
                        size={0.7} fontWeight={600}
                        minWidth={columnSet.includes(column.field) ? column.minWidth : 0} 
                        key={i}
                    ></TopTd>)}
                <TopTd style={{textAlign: "right", paddingTop: condensed ? "1em" : "2em"}} size={0.7} bold>
                    {formattedCurrency(section.client_quoted, currency, true, showDecimal ? 2 : 0)}
                </TopTd>
            </tr>
            {section.topsheet_show ?
                <>
                    {section.children.map(category=>
                        <TopSheetCategory
                            key={category}
                            id={category}
                            currency={currency}
                            showZero={showZero}
                            showDecimal={showDecimal}
                            printMode={printMode}
                            columnSet={columnSet}
                            viewOnly={viewOnly}
                            condensed={condensed}
                        />
                    )}
                </>
            : null}
        </>
    )
}

export const TopSheetCategory = ({id, currency, showZero, showDecimal, printMode, columnSet, viewOnly, condensed}) => {
    const category = useSelector(state => selectGroup(state, id))
    if (!category){
        return
    }
    if (category.client_quoted == 0 && !showZero){
        return
    }
    return (
        <>
            <tr key={id}>
                <TopTd bold condensed={condensed} style={{borderTop: "1px #ddd solid", paddingTop: "0.5em"}} size={0.6} >{category.name}</TopTd>
                {topSheetColumns.slice(1,-1).map((column, i)=>
                    <TopTd style={{borderTop: "1px #ddd solid", paddingTop: "0.5em"}}
                        minWidth={columnSet.includes(column.field) ? column.minWidth : 0} 
                        size={0.6} fontWeight={600} key={i}
                    ></TopTd>)}
                <TopTd bold condensed={condensed} style={{borderTop: "1px #ddd solid", textAlign: "right", paddingTop: "0.5em"}} size={0.6} >
                    {formattedCurrency(category.client_quoted, currency, true, showDecimal ? 2 : 0)}
                </TopTd>
            </tr>
            {category.topsheet_show?
                <>
                    <HeaderRow projectCurrency={currency} visibleColumns={category.topsheet_columns} columnSet={columnSet} rateType={category.rate_type}/>
                    {category.line_items.map((line_id, index)=>
                        <LineItemRow
                            key={line_id}
                            id={line_id}
                            currency={currency}
                            showZero={showZero}
                            showDecimal={showDecimal}
                            visibleColumns={category.topsheet_columns}
                            printMode={printMode}
                            columnSet={columnSet}
                            lastRow={index==category.line_items.length-1}
                            rateType={category.rate_type}
                            viewOnly={viewOnly}
                            condensed={condensed}
                        />
                    )}
                </>
            : null}
        </>
    )
}

export const TopAddon = ({name, amount, currency, showDecimal, showZero, condensed}) => {
    if (amount == 0 && !showZero){
        return
    }
    return (
        <div style={{marginLeft: "50%", display: "flex", justifyContent: "space-between", padding: condensed ? "0" : "0.5em 0", borderBottom: "1px #ddd solid"}}>
            <TopSpan size={0.7}>{name}</TopSpan>
            <TopSpan size={0.7}>{formattedCurrency(amount, currency, true, showDecimal ? 2 : 0)}</TopSpan>
        </div>
    )
}

export const Image = ({image, printMode, size}) => {
    if (!image) {
        return null
    }
    const imageUrl = image.processed ? image.url_medium : image.url_orig
    return (
        <img 
            src={imageUrl} 
            style={{height: `${size}em`}} 
        />
    )
}

export const Description = ({title, text, show, condensed}) => {
    if (!show){
        return
    }
    return (
        <div style={{paddingBottom: condensed ? "0.5em" : "1em"}}>
            <TopP size={0.7} bold style={{padding: "0.5em 0 0.5em 0"}}>{title}</TopP>
            <TopP size={0.6} style={{whiteSpace : "pre-line"}}>{text}</TopP>
        </div>
    )
}

export const Signature = ({printMode, name}) => {
    const signatureIdentidierStyle = {color: printMode ? "transparent" : "#00000000", pointerEvents: "none", position: "absolute"}
    return(
        <div style={{width: "45%", pageBreakBefore: "always"}}>
            <TopP size={0.5}>Authorized Signature to confirm approval of above costs and terms:</TopP>

            <div style={{position: "relative", height: printMode ? "8em" : "5em", borderBottom: "0.01em black solid", marginBottom: "1em"}}>
                <span style={{...signatureIdentidierStyle, fontSize: "2.5em", bottom: 0, padding: "0.2em"}}>
                    {TOPSHEET_SIGNATURE_LOCATION_IDENTIFYER_STRING}
                </span>
            </div>

            <div style={{position: "relative"}}>
                <TopP style={{paddingBottom: "1em"}} size={0.5}>
                    Name: {name}
                </TopP>
                <span style={{...signatureIdentidierStyle,  top: 0, transform: "translate(2em, -30%)"}}>
                    {TOPSHEET_NAME_LOCATION_IDENTIFYER_STRING}
                </span>
            </div>
            <div style={{position: "relative"}}>
                <TopP size={0.5}>
                    Date: 
                </TopP>
                <span style={{...signatureIdentidierStyle,  top: 0, transform: "translate(2em, -30%)"}}>
                    {TOPSHEET_DATE_LOCATION_IDENTIFYER_STRING}
                </span>
            </div>
        </div>
    )
}

export const ExtraInfo = () => {
    const extraInfo = useSelector(selectDetails).top_sheet_extra_info
    return (
        <>
            {extraInfo ? <TopP size={0.6}>{extraInfo}</TopP> : null}
        </>
    )
}

export const SenderNames = ({ condensed }) => {
    const senders = useSelector(selectDetails).top_sheet_senders
    if (!senders){
        return
    }
    const senderNames = []
    senders.forEach(sender=>{
        senderNames.push(`${sender.first_name} ${sender.last_name}`)
    })
    return (
        <TopP size={0.6} style={{paddingBottom: condensed ? "0.5em" : "1em"}}>
            {`Drafted by ${senderNames.join(", ")}`}
        </TopP>
    )
}

export const BidBills = ({show, projectCurrency}) => {
    const bidBills = useSelector(selectBills)
    if (bidBills.length == 0 || !show){
        return
    }
    const topTdStyle = (billsLength, index) => {
        return {borderBottom: index != billsLength -  1 ? "0.5px solid #aaa" : null }
    } 
    return (
        <div style={{paddingBottom: "1em"}}>
            <TopP size={0.7} bold style={{padding: "0.5em 0 0.5em 0"}}>{"Billing Schedule"}</TopP>
            <table style={{width: "100%", borderSpacing: 0}}>
                <thead>
                    <tr>
                        <TopTd grey size={0.6}></TopTd>
                        <TopTd style={{padding: 0 }} grey size={0.6}>{"Amount"}</TopTd>
                        <TopTd grey size={0.6}>{"Date"}</TopTd>
                        <TopTd style={{textAlign: "right"}} grey align="right" size={0.6}>{"Total"}</TopTd>
                    </tr>
                </thead>
                <tbody>
                    {bidBills.map((bill, index)=>
                        <tr key={index}>
                            <TopTd style={topTdStyle(bidBills.length, index)} size={0.6}>{
                                <>
                                    <span>{index + 1}</span>
                                    <sup>{getNth(index + 1)}</sup>
                                    <span>{" Bill"}</span>
                                </>
                            }</TopTd>
                            <TopTd style={topTdStyle(bidBills.length, index)} size={0.6}>{`${(bill.quoted_percentage * 100).toFixed()}%`}</TopTd>
                            <TopTd style={topTdStyle(bidBills.length, index)} size={0.6}>{bill.bill_date}</TopTd>
                            <TopTd style={{...topTdStyle(bidBills.length, index), textAlign: "right" }} size={0.6}>{formattedCurrency(bill.actual_amount, projectCurrency)}</TopTd>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}