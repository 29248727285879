import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Icon, TextField, Button, Snackbar, Alert, Grid, Link } from "@mui/material"
import { useTheme } from "@mui/material"
import IosShareRounded from "@mui/icons-material/IosShareRounded"

import { DottedDiv } from "./StyledComponents"
import { formattedCurrency } from "@/utils"


export const InternalLink = ({ href, color, sx, children }) => {
    const navigate = useNavigate()

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        navigate(href)
    }

    return (
        <Link href={href} color={color || "inherit"} sx={sx || {}} onClick={handleClick}>
            {children}
        </Link>
    )
}

export const LimitedTextField = (props) => {
    const { maxLength, value } = props
    return (
        <TextField 
            {...props}
            value={value || ""}
            inputProps={{ maxLength: maxLength, style: {paddingRight: 10} }}
            InputProps={{ endAdornment: 
                <div>
                    <span style={{color: "grey"}}>
                        {`${(value || "").length}/${maxLength}`}
                    </span> 
                </div>
            }}
        />
    )
}

export const CopyLinkButton = ({}) => {
    const [ showAlert, setShowAlert ] = useState(false)
    const handleCopyLink = () => {
      setShowAlert(true)
      navigator.clipboard.writeText(window.location.href)
    }
    return (
        <>
            <Button
                onClick={handleCopyLink}
                variant="contained" color="secondary" size="small"
                sx={{boxShadow: "none", minWidth: 0, width: "28px"}}
            >
                <IosShareRounded fontSize="small"/>
            </Button>
            <Snackbar
                open={showAlert}
                autoHideDuration={2000}
                onClose={(event, reason) => {setShowAlert(false)}}
            >
                <Alert severity="success">Link copied</Alert>
            </Snackbar>
        </>
    )
}

export const DottedSum = ({icon, color, title, subTitle, amount, subAmount, currency, isPercentage}) => {
    const Icon = icon
    const theme = useTheme()
    return(
        <DottedDiv overlay backgroundColor={theme.palette.background.paper} dotColor={"#99999911"}>
            <Grid container alignItems={"center"} sx={{padding: 1.5,}}>
                <Grid container>
                    <Grid item>
                        <Icon fontSize="tiny" sx={{color: color, background: "white", padding: 0.3, borderRadius: 1, marginRight: 1 }}/>
                    </Grid>
                    <Grid item><span style={{fontSize: 12}}>{title}</span></Grid>
                    <Grid item>&nbsp;/&nbsp;</Grid>
                    <Grid item><span style={{fontSize: 12, color: "gray"}}>{subTitle}</span></Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                    <h2 style={{margin: 0, marginBottom: 10, marginTop: 40}}>{isPercentage ? `${(amount*100).toFixed()}%` : formattedCurrency(amount, currency)}</h2>
                </Grid>
                <Grid item md={12}>
                    <span style={{color: "gray"}}>{isPercentage ? `${(subAmount*100).toFixed()}%` : formattedCurrency(subAmount, currency) }</span>
                </Grid>
            </Grid>
        </DottedDiv>
    )
}

export const MakersIcon = ({size}) => {
    const theme = useTheme()
    return (
        <Icon sx={{width: size || 24, height: size || 24, display: "flex"}}>
            <img src={`/logo_${theme.palette.mode}.png`} height={size || 24} width={size || 24} />
        </Icon>
    )
}

export const CustomIcon = ({size, imgUrl}) => {
    const theme = useTheme()
    return (
        <Icon sx={{width: size || 24, height: size || 24, display: "flex"}}>
            <img src={imgUrl} height={size || 24} width={size || 24} />
        </Icon>
    )
}

export const RadioButton = ({ theme, checked, label, ...props }) => {
    return (
        <Button {...props}
            style={{
                height: 38,
                textTransform: "none",
                width: "100%",
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
                outline: checked ? `solid 2px ${theme.palette.primary.main}` : "none",
            }}
        >
           {label}
        </Button>
    )
}
