import { CustomBox } from "@/common/StyledComponents"
import { Alert, Button, Grid, Modal, Snackbar, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import { cancelConfirmingRefutedBill, selectConfirmingRefutedBill } from "./actualSlice"
import { useDispatch } from "react-redux"
import { request } from "@/Api"
import { useState } from "react"
import { updateBill } from "./actualSlice"

export default function AccountingBillRefutalConfirmationPopup({getProject}){
    const [ snackBarMessage, setSnackBarMessage ] = useState(null)
    const dispatch = useDispatch()
    const confirmingRefutedBill = useSelector(selectConfirmingRefutedBill)
    const theme = useTheme()

    const handleClose = () => {
        dispatch(cancelConfirmingRefutedBill())
    }

    const handleResolve = () => {
        request.post(`accounting-bills/${confirmingRefutedBill.id}/resolve-refutal`)
        .then((response)=>{
            dispatch(updateBill(response.data))
            setSnackBarMessage("Bill refutal resolved")
            handleClose()
            getProject()
        })
    }

    return(
        <>
            {confirmingRefutedBill && 
            <Modal onClose={handleClose} open={confirmingRefutedBill != null}>
                <CustomBox sx={{background: theme.palette.background.default, padding: 4}}> 
                    <Grid container alignItems={"center"} spacing={3}>
                        <Grid item>
                            <h1>Restore refuted bill</h1>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <p>{`${confirmingRefutedBill.vendor_name} ${confirmingRefutedBill.accounting_bill_number}`}</p>
                    </Grid>

                    <Grid container spacing={2} justifyContent={"right"}>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="success" onClick={handleResolve}>Restore</Button>
                        </Grid>
                    </Grid>
                </CustomBox>
            </Modal>}

            <Snackbar
                open={snackBarMessage != null}
                autoHideDuration={2000}
                onClose={() => {setSnackBarMessage(null)}}
            >
                <Alert severity="success">{snackBarMessage}</Alert>
            </Snackbar>
        </>
    )
}