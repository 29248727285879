import { createSlice } from '@reduxjs/toolkit'

const defaultBidPopupStatus = {
    open: false, // boolean
    type: "", // "Bid Sheet" or "Change Order"
    project: null, // project object
}
const defaultOpen = localStorage.getItem("navOpen") == "false" ? false : true

const defaultError = {
    open: false,
    message: null,
    autoHideDuration: null,
    severity: "error",
}

export const navSlice = createSlice({
    name: "nav",
    initialState: {
        navOpen: defaultOpen,
        projectPopupOpen: false,
        bidPopupStatus: {
            ...defaultBidPopupStatus,
        },
        error: {
            ...defaultError, 
        },
    },
    reducers: {
        setNavOpen: (state, action) => {
            state.navOpen = action.payload
        },
        openProjectPopup: (state) => {
            state.projectPopupOpen = true
        },
        closeProjectPopup: (state) => {
            state.projectPopupOpen = false
        },
        openBidPopup: (state, action) => {
            state.bidPopupStatus = {
                ...defaultBidPopupStatus,
                open: true,
                ...action.payload,
            }
        },
        closeBidPopup: (state) => {
            state.bidPopupStatus = {
                ...defaultBidPopupStatus
            }
        },
        setError: (state, action) => {
            state.error = {
                ...defaultError,
                open: true,
                message: action.payload.message,
                severity: action.payload.severity || defaultError.severity,
                autoHideDuration: action.payload.autoHideDuration || defaultError.autoHideDuration, // won't work when setting to null if defaultError.autoHideDuration is not null
            }
        },
        clearError: (state) => {
            state.error = {
                ...defaultError
            }
        },
    }
})

export const {
    setNavOpen, openProjectPopup, closeProjectPopup,
    openBidPopup, closeBidPopup,
    setError, clearError,
} = navSlice.actions

export const selectNavOpen = (state) => state.nav.navOpen
export const selectProjectPopupOpen = (state) => state.nav.projectPopupOpen
export const selectBidPopupStatus = (state) => state.nav.bidPopupStatus
export const selectError = (state) => state.nav.error

export default navSlice.reducer
