import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from 'react-beautiful-dnd';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@mui/material/Grid";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from "@mui/material/IconButton";
import { InputAdornment, TextField } from "@mui/material";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import ConfirmationDialog from "@/common/ConfirmationDialog";
import InlineRename from "@/bid/InlineRename";
import { formattedCurrency } from "@/utils";
import { request } from "@/Api";
import { addCategory, addSection, deleteSection, deleteCategory, editGroup, updateGroup, selectSearchValue, selectCategoryHighlights, selectSectionHighlights } from "@/bid/bidSlice";

export default function BidAccordion(props) {
    const { 
        initialExpanded, expandAll, reMarkup, extraSummaryPadding,
        deleteMessage, sx, readOnly, 
        children, draggableId, draggableIndex, 
        lineGroup, lineGroupType, currency, sendSocketMessage
    } = props
    const [ expanded, setExpanded ] = useState(false)

    const [ anchorEl, setAnchorEl ] = useState(null)
    const [ showNewNameInput, setShowNewNameInput ] = useState(false)
    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ showReMarkupDialog, setShowReMarkupDialog ] = useState(false)
    const [ reMarkupValue, setReMarkupValue ] = useState("")

    const searchValue = useSelector(selectSearchValue)
    const highlights = lineGroupType == 
    "category" ? useSelector(state => selectCategoryHighlights(state, lineGroup.id)) :
    "section" ? useSelector(state => selectSectionHighlights(state, lineGroup.id)) : []

    const dispatch = useDispatch()

    useEffect(() => {
        setExpanded(expandAll)
    }, [expandAll])

    useEffect(() => {
        setExpanded(initialExpanded)
    }, [])

    const handleMenuOpen = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = (event) => {
        if (event){
            event.stopPropagation()
        }
        setAnchorEl(null)
    }

    const handleDelete = (event) => {
        event.stopPropagation()
        setShowDeleteDialog(true)
        handleMenuClose()
    }
    const submitDelete = () => {
        request.delete(`/bids/${lineGroup.bid_id}/line-groups/${lineGroup.id}`).then(() => {
            dispatch(lineGroup.parent_id ? deleteCategory(lineGroup) : deleteSection(lineGroup))
            sendSocketMessage({type: "deleteGroup", group: lineGroup})
        })
    }

    const handleRename = (event) => {
        event.stopPropagation()
        setShowNewNameInput(true)
        handleMenuClose()
    }
    const closeRename = (event) => {
        if (event) {
            event.stopPropagation()
        }

        setShowNewNameInput(false)
    }
    const submitRename = (value) => {
        renameGroup(value)
        closeRename()
    }

    const handleEdit = (event) => {
        event.stopPropagation()
        dispatch(editGroup(lineGroup))
        handleMenuClose()
    }

    const submitReMarkup = (markup) => {
        reMarkup(markup)
        setReMarkupValue("")
    }

 
    const renameGroup = (value) => {
        request.put(`/bids/${lineGroup.bid_id}/line-groups/${lineGroup.id}`, {
            ...lineGroup,
            name: value
        }).then((response) => {
            dispatch(updateGroup(response.data))
        })
    }
    
    const copyGroup = () => {
        request.post(`/bids/${lineGroup.bid_id}/line-groups/${lineGroup.id}/copy`)
        .then((response) => {
            const newGroup = response.data
            dispatch(newGroup.parent_id ? addCategory({...newGroup, initialExpanded: true}) : addSection(newGroup))
            sendSocketMessage({ type: "addGroup", groupId: newGroup.id })
        })
        handleMenuClose()
    }

    return (
        <Draggable draggableId={draggableId} index={draggableIndex} key={draggableId}>
            {(provided)=>
                <Accordion
                    TransitionProps={{unmountOnExit: true}} // this fixes strange draggable bug
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    expanded={searchValue ? true : expanded}
                    sx={{...sx, position: "relative", border: (!expanded&&highlights.length>0) ? `${highlights[0].color} 3px solid` : "none", borderRadius:1}}
                >
                    <AccordionSummary
                        sx={{
                            padding: extraSummaryPadding ? "0 24px" : "0 16px",
                            "& .MuiAccordionSummary-contentGutters": {
                                cursor: "default",
                            },
                            "& .MuiAccordionSummary-root": {
                                cursor: "default",
                            },
                        }}
                        expandIcon={
                            <IconButton onClick={() => { setExpanded(!expanded) }}>
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                    >
                        {(!expanded&&highlights.length>0) ? 
                        <p style={{
                            fontSize: 12, 
                            position: "absolute", 
                            margin: 0, 
                            background: highlights[0].color, 
                            transform: "translateY(-100%)", 
                            borderRadius: 5, 
                            padding: 3, 
                            right: 0
                        }}>
                            {highlights.map(highlight=>highlight.personName).join(", ")}
                        </p> : null}

                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid container item sm={6} justifyContent="flex-start" alignItems={"center"}>
                                <Grid container item sm={6} alignItems={"center"}>
                                    { showNewNameInput ? (
                                        <Grid item>
                                            
                                            <InlineRename
                                                defaultName={lineGroup.name}
                                                close={closeRename}
                                                rename={submitRename}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid container alignItems={"center"} wrap="nowrap">
                                            <Grid item { ...provided.dragHandleProps }>
                                                <DragHandleIcon />
                                            </Grid>
                                            <Grid item
                                                style={{fontWeight: lineGroupType=="section" ? 600 : 100, margin: "0px 0px 0px 10px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                                                onClick={handleRename}
                                            >
                                                {lineGroup.name}
                                            </Grid>
                                            {readOnly ? null : (
                                                <Grid item>
                                                    <IconButton sx={{marginLeft: "6px"}} onClick={handleMenuOpen}>
                                                        <MoreVertIcon fontSize="small" sx={{pointerEvents: "none"}} />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleMenuClose}
                                                        >
                                                        <MenuItem onClick={handleEdit}>
                                                            <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
                                                            <ListItemText>Batch Edit</ListItemText>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleDelete}>
                                                            <ListItemIcon><DeleteSweepIcon fontSize="small" /></ListItemIcon>
                                                            <ListItemText>Delete</ListItemText>
                                                        </MenuItem>
                                                        <MenuItem onClick={copyGroup}>
                                                            <ListItemIcon><ContentCopyRoundedIcon fontSize="small" /></ListItemIcon>
                                                            <ListItemText>Duplicate</ListItemText>
                                                        </MenuItem>
                                                    </Menu>
                                                    <ConfirmationDialog
                                                        open={showDeleteDialog}
                                                        closeDialog={() => setShowDeleteDialog(false)}
                                                        title={`Delete "${lineGroup.name}"?`}
                                                        callback={submitDelete}
                                                        >
                                                        <p>{deleteMessage}</p>
                                                    </ConfirmationDialog>
                                                    <ConfirmationDialog
                                                        open={showReMarkupDialog}
                                                        closeDialog={() => setShowReMarkupDialog(false)}
                                                        title={`Set markups for "${lineGroup.name}"`}
                                                        callback={()=>{submitReMarkup(reMarkupValue/100)}}
                                                        >
                                                        <span>This will affect</span><span style={{fontWeight: 600}}> all line items </span><span>{`in "${lineGroup.name}"`}</span> <br/><br/>
                                                        <TextField
                                                            onWheel={ event => event.target.blur() }
                                                            type="number"
                                                            value={reMarkupValue}
                                                            onChange={(e)=>{setReMarkupValue(e.target.value)}}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                            }}
                                                            inputProps={{
                                                                style: {textAlign: "right"}
                                                            }}
                                                        />
                                                    </ConfirmationDialog>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item fontSize={11} color={"grey"}>
                                    {lineGroup.is_makers_fees ? "Internal" : "External"}
                                </Grid>
                            </Grid>
                            <Grid container item sm={6} justifyContent="flex-end" alignItems={"center"}>
                                <NumberWithLabel label="Markup" number={lineGroup.average_markup} isPercentage />
                                <NumberWithLabel label="Budget" number={lineGroup.client_budget} currency={currency} />
                                <NumberWithLabel label="Gross Profit" number={lineGroup.client_gross_profit} currency={currency} />
                                <NumberWithLabel label="Total" number={lineGroup.client_quoted} important currency={currency}/>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: 1}}>
                        { children }
                    </AccordionDetails>
                </Accordion>
            }
        </Draggable>
    )
}

function NumberWithLabel({number, currency, label, isPercentage, important}){
    return (
        <Grid textAlign={"right"} marginLeft="24px" width={isPercentage ? 40 : 100}>
            <span style={{color: "grey", fontSize: 11}}>
                {label}
            </span> 
            <br/>
            <span style={{color: important ? "auto" : "grey", fontSize: 14, userSelect: "text"}}>
                {isPercentage ? `${(number * 100).toFixed()}%` : formattedCurrency(number, currency)}
            </span>
        </Grid>
    )
}