import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Alert, Button, Grid, InputLabel, Modal, Snackbar, TextField, useTheme } from "@mui/material"

import { updateBill, cancelRefutingBill, selectRefutingBill } from "@/actuals/actualSlice"
import { CustomBox } from "@/common/StyledComponents"
import { RadioButton } from "@/common/CommonComponents"
import { request } from "@/Api"
import { ACCOUNTING_BILL_STATUS_REFUTE_HST, ACCOUNTING_BILL_STATUS_REFUTE_OTHER } from "@/actuals/constants"

export default function AccountingBillRefutePopup({getProject}){
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ message, setMessage ] = useState("")
    const [ reason, setReason ] = useState("")
    const theme = useTheme()
    const dispatch = useDispatch()
    const refutingBill = useSelector(selectRefutingBill)

    const handleClose = () => {
        setMessage("")
        setReason("")
        dispatch(cancelRefutingBill())
    }

    const handleReasonUpdate = (event) => {
        setReason(event.target.getAttribute("value"))
    }

    const handleMessageInput = (event) => {
        setMessage(event.target.value)
    }

    const handleSubmit = () => {
        request.post(`accounting-bills/${refutingBill.id}/refute`, {reason, message})
        .then(response=>{
            dispatch(updateBill(response.data))
            setSnackBarOpen(true)
            handleClose()
            getProject()
        })
    }

    return(
        <>
            {refutingBill &&
            <Modal onClose={handleClose} open={refutingBill != null}>
                <CustomBox sx={{background: theme.palette.background.default, padding: 4}}>
                    <Grid container alignItems={"center"} spacing={3}>
                        <Grid item>
                            <h1>Refute Bill</h1>
                        </Grid>
                        <Grid item>
                            <span>{`${refutingBill.vendor_name} ${refutingBill.accounting_bill_number}`}</span>
                        </Grid>
                    </Grid>

                    <InputLabel sx={{paddingBottom: 3}}>
                        Please provide a reason for this refutal:
                    </InputLabel>

                    <Grid container spacing={3}>
                        <Grid md={6} item>
                            <RadioButton
                                value={ACCOUNTING_BILL_STATUS_REFUTE_HST}
                                label="HST"
                                checked={reason == ACCOUNTING_BILL_STATUS_REFUTE_HST}
                                onClick={handleReasonUpdate}
                                theme={theme}
                                />
                        </Grid>
                        <Grid md={6} item>
                            <RadioButton
                                value={ACCOUNTING_BILL_STATUS_REFUTE_OTHER}
                                label="Other"
                                checked={reason == ACCOUNTING_BILL_STATUS_REFUTE_OTHER}
                                onClick={handleReasonUpdate}
                                theme={theme}
                            />
                        </Grid>
                    </Grid>

                    { reason == ACCOUNTING_BILL_STATUS_REFUTE_OTHER && (
                        <TextField
                            multiline
                            minRows={4}
                            fullWidth
                            value={message}
                            onInput={handleMessageInput}
                            sx={{marginTop: 2}}
                        />
                    )}

                    <Grid container spacing={2} justifyContent={"right"} sx={{marginTop: 2}}>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit} disabled={reason == ""}>Submit</Button>
                        </Grid>
                    </Grid>
                </CustomBox>
            </Modal>}

            <Snackbar
                open={snackBarOpen}
                autoHideDuration={2000}
                onClose={() => {setSnackBarOpen(false)}}
            >
                <Alert severity="success">Bill refutal requested!</Alert>
            </Snackbar>
        </>
    )
}