import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { parse, format } from "date-fns"

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'

import { useTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { request } from "@/Api"
import ApiAutocomplete from '@/common/ApiAutocomplete';
import ClientPopup from '@/clients/ClientPopup';
import ProjectForecast from '@/project/ProjectForecast'
import { Title, ErrorMessage } from "@/common/StyledComponents"
import { baseTheme } from '@/themes'
import { ACCOUNTING_STRATEGY, currencies } from '@/constants'
import { getCurrencySymbol } from '@/utils'
import { closeProjectPopup, setError } from '@/nav/navSlice'
import { selectHasRole, selectHasStrategy, selectPersonId } from '@/auth/authSlice'


const CustomBox = styled(Box)`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 86%;
    padding: 40px 50px;
    border-radius: 20px;
    background-color: ${props => props.backgroundColor};
`

const Icon = styled.div`
    display: flex;
    padding: 6px;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor}; 
    margin-right: 12px;
`

const Form = styled.div`
    margin: 24px 0;
    overflow: auto;
`
const InputLabel = styled.div`
    padding-bottom: 4px;
    font-size: 12px;
    color: ${props => props.color};
`
const CreateClient = styled.div`
    display: inline-block;
    margin-left: 6px;
    cursor: pointer;
    color: ${props => props.color};
`

const requiredProjectFields = [
    "name",
    "legal_entity_id",
    "currency",
    "project_type_id",
    "client_id",
    "owner_id",
    "status_id",
    "forecast_revenue",
    "forecast_schedule",
    "forecast_certainty",
]
const requiredForecastBillFields = [
    "percentage",
    "date",
]

export default function CreateProjectPopup() {
    const theme = useTheme()
    const navigate = useNavigate()
    const scrollRef = useRef()
    const dispatch = useDispatch()

    const personId = useSelector(selectPersonId)
    const isOwner = useSelector(state => selectHasRole(state, "project_owner"))

    const defaultData = {
        owner_id: isOwner ? personId : null,
        forecast_bills: []
    }

    const hasAccountingStrategy = useSelector((state) => selectHasStrategy(state, ACCOUNTING_STRATEGY))

    const [project, setProject] = useState({ ...defaultData })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [errorMesssage, setErrorMessage] = useState("")
    const [clients, setClients] = useState([])
    const [clientPopupOpen, setClientPopupOpen] = useState(false)

    const getClients = () => {
        request.get("clients")
        .then((response)=>{
            setClients(response.data)
        })
    }

    const handleClose = () => {
        dispatch(closeProjectPopup())
    }

    const handleProjectCreate = () => {
        const reducer = (errors, error) => (errors[error] = "Field required", errors)
        const missingRequiredFields = requiredProjectFields.filter(field => !project[field]).reduce(reducer, {})
        const missingForecastBillFields = {}
        if (hasAccountingStrategy){
            for (let i in project.forecast_bills) {
                const missing = requiredForecastBillFields.filter(field => !project.forecast_bills[i][field]).reduce(reducer, {})
                if (Object.keys(missing).length) {
                    missingForecastBillFields[i] = missing
                }
            }
            if (Object.keys(missingForecastBillFields).length) {
                missingRequiredFields.forecast_bills = {
                    ...missingForecastBillFields,
                }
            }
            if (Object.keys(missingRequiredFields).length) {
                setErrors(missingRequiredFields)            
                return
            }
        }

        setErrors({})
        setErrorMessage("")
        setLoading(true)
        
        request.post("projects", {
            ...project,
            model_type: hasAccountingStrategy ? "project_create" : "project_create_optional_forecast" // Discriminator
        }).then((response) => {
            let project = response.data
            if (response.data.warning) {
                dispatch(setError({
                    message: response.data.warning,
                    severity: "warning",
                }))
                project = response.data.project
            }

            navigate(`/projects/${project.id}`)
            handleClose()

        }).catch((error) => {
            if (error?.response?.status == 422){
                const details = error.response.data.detail
                const currentErrors = {}
                for (var i in details){
                    const field = details[i].loc[details[i].loc.length-1]
                    const msg = details[i].msg
                    if (details[i].loc[1] == "forecast_bills") {
                        if (!currentErrors.forecast_bills) {
                            currentErrors.forecast_bills = {}
                        }
                        const index = details[i].loc[2]
                        currentErrors.forecast_bills[index] = {
                            ...currentErrors.forecast_bills[index],
                            [field]: msg,
                        }
                    } else {
                        currentErrors[field] = msg
                    }
                }
                setErrors(currentErrors)
            } else {
                console.log(error)
                setErrorMessage("Failed to create project. Please report this issue in Slack #product_makers_central.")
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleInputChange = (fieldName) => (event) => {
        setProject({...project, [fieldName]: event.target.value})

        if (event.target.value && errors[fieldName]) {
            const { [fieldName]: _, ...remainingErrors } = errors
            setErrors(remainingErrors)
        }
    }

    const handleCalendarChange = (fieldName) => (newValue) => {
        let date = newValue
        if (date != null) {
            try {
                date = format(newValue, "y-MM-dd")
            } catch (e) {
                date = ""
            }
        }

        setProject({...project, [fieldName]: date})
    }

    const handleAutocompleteChange = (fieldName) => (event, newValue) => {
        if(fieldName == "client_id"){
            let client_currency = clients.find((client)=>newValue==client.id)?.currency
            setProject({...project, [fieldName]: newValue, ["currency"]: client_currency})
        }else{
            setProject({...project, [fieldName]: newValue})
        }
        if (newValue && errors[fieldName]) {
            const { [fieldName]: _, ...remainingErrors } = errors
            setErrors(remainingErrors)
        }
    }

    const handleLoadApiAutocomplateResponseData = (idField, isMultiChoice = false) => (data) => {
        if (Array.isArray(data) && data.length == 1){
            setProject(projectState => { // use setState callback to set state synchronously
                const newProjectState = {...projectState, [idField]: isMultiChoice ? [data[0].id] : data[0].id}
                return newProjectState
            })
        }
    }

    // Auto-scroll to bottom when bills are added, to ensure they are in view
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scroll({ top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight, left: 0, behavior: 'smooth' });
        }
    }, [project.forecast_bills.length])

    useEffect(()=>{
        getClients()
        setErrors({})
    }, [])

    return(
        <CustomBox backgroundColor={theme.palette.background.default}>
            <Title borderColor={theme.palette.background.paper}>
                <Icon backgroundColor={baseTheme.palette.primary.light}>
                    <WorkOutlineOutlinedIcon fontSize="small" sx={{color: baseTheme.palette.text.primary}} />
                </Icon>
                Create new Project
            </Title>

            <Form ref={scrollRef}>
            <Grid container spacing={3} width="100%">
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Project Name</InputLabel>
                        <TextField
                            sx={{width:"100%"}}
                            value={project.name || ""}
                            onChange={handleInputChange("name")}
                            error={"name" in errors}
                            helperText={errors["name"]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>
                            Client
                            <CreateClient onClick={()=>{setClientPopupOpen(true)}} color={theme.palette.primary.main}>
                                (or create one)
                            </CreateClient>
                        </InputLabel>
                        <Autocomplete
                            value={project.client_id || null}
                            disablePortal
                            options={clients.map((client)=>client.id)}
                            getOptionLabel={(id) => clients.find((client)=>id==client.id)?.name || ""}
                            renderInput={(params) => <TextField {...params} error={"client_id" in errors} helperText={errors["client_id"]} />}
                            onChange={handleAutocompleteChange("client_id")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Producer</InputLabel>
                        <ApiAutocomplete
                            value={project.owner_id ? project.owner_id : null}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            onChange={handleAutocompleteChange("owner_id")}
                            error={"owner_id" in errors}
                            helperText={errors["owner_id"]}
                            onLoadResponseData={handleLoadApiAutocomplateResponseData("owner_id")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Project Type</InputLabel>
                        <ApiAutocomplete
                            value={project.project_type_id ? project.project_type_id : null}
                            apiRoute={"project-types"}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("project_type_id")}
                            error={"project_type_id" in errors}
                            helperText={errors["project_type_id"]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Project Status</InputLabel>
                        <ApiAutocomplete
                            value={project.status_id || null}
                            apiRoute={"statuses"}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("status_id")}
                            error={"status_id" in errors}
                            helperText={errors["status_id"]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Project Currency</InputLabel>
                        <Autocomplete
                            value={project.currency || null}
                            disablePortal
                            options={currencies}
                            renderInput={(params) => <TextField {...params} error={"currency" in errors} helperText={errors["currency"]} />}
                            onChange={handleAutocompleteChange("currency")}
                        />
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel color={theme.palette.text.secondary}>Start Date</InputLabel>
                            <DatePicker
                                value={project.start_date ? parse(project.start_date, "y-MM-dd", new Date()) : null}
                                onChange={handleCalendarChange("start_date")}
                                renderInput={(params) => <TextField fullWidth {...params} error={"start_date" in errors} helperText={errors["start_date"]} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel color={theme.palette.text.secondary}>End Date</InputLabel>
                            <DatePicker
                                value={project.end_date ? parse(project.end_date, "y-MM-dd", new Date()) : null}
                                onChange={handleCalendarChange("end_date")}
                                renderInput={(params) => <TextField fullWidth {...params} error={"end_date" in errors} helperText={errors["end_date"]} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel color={theme.palette.text.secondary}>Live Date</InputLabel>
                            <DatePicker
                                value={project.live_date ? parse(project.live_date, "y-MM-dd", new Date()) : null}
                                onChange={handleCalendarChange("live_date")}
                                renderInput={(params) => <TextField fullWidth {...params} error={"live_date" in errors} helperText={errors["live_date"]} />}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Entity</InputLabel>
                        <ApiAutocomplete
                            value={project.legal_entity_id ? project.legal_entity_id : null}
                            apiRoute={"legal-entities"}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("legal_entity_id")}
                            error={"legal_entity_id" in errors}
                            helperText={errors["legal_entity_id"]}
                            onLoadResponseData={handleLoadApiAutocomplateResponseData("legal_entity_id")}
                        />
                    </Grid>
                    <Grid item sm={12} md={8}>
                        <InputLabel color={theme.palette.text.secondary}>Team Members</InputLabel>
                        <ApiAutocomplete
                            value={project.person_ids ? project.person_ids : []}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            onChange={handleAutocompleteChange("person_ids")}
                            multiple
                            error={"person_ids" in errors}
                            helperText={errors["person_ids"]}
                            onLoadResponseData={handleLoadApiAutocomplateResponseData("person_ids", true)}
                        />
                    </Grid>
                </Grid>

                <ProjectForecast
                    forecast={{
                        forecast_revenue: project.forecast_revenue || null,
                        forecast_schedule: project.forecast_schedule || null,
                        forecast_certainty: project.forecast_certainty || null,
                        forecast_bills: project.forecast_bills || []
                    }}
                    setForecast={(forecast) => setProject({
                        ...project,
                        ...forecast,
                    })}
                    errors={{
                        forecast_revenue: errors.forecast_revenue,
                        forecast_schedule: errors.forecast_schedule,
                        forecast_certainty: errors.forecast_certainty,
                        forecast_bills: errors.forecast_bills || {}
                    }}
                    setErrors={(forecastErrors) => setErrors({
                        ...errors,
                        ...forecastErrors,
                    })}
                    currency={getCurrencySymbol(project.currency || "CAD")}
                    cacheForecastField={() => {}}
                    saveForecastField={() => {}}
                    saveForecast={() => {}}
                    createMode
                    defaultExpanded
                />
            </Form>

            <Grid container rowSpacing={3}>
                <Grid item xs={12} sm={6} md={8} direction="row-reverse">
                    <ErrorMessage>{errorMesssage}</ErrorMessage>
                </Grid>
                <Grid item container xs={12} sm={6} md={4} columnSpacing={2} justifyContent="flex-end">
                    <Grid item>
                        {loading ? <CircularProgress/> : null}
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handleClose} disabled={loading}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleProjectCreate} disabled={loading}>Create</Button>
                    </Grid>
                </Grid>
            </Grid>

            <ClientPopup 
                isNew={true} 
                popupOpen={clientPopupOpen} 
                setPopupOpen={setClientPopupOpen}
                clients={clients}
                setClients={setClients} 
                activeClient={{}}
            />
        </CustomBox>
    )
}
