import styled from "styled-components"

export const AuthContainer = styled.div`
    min-height: 100%;
    position: relative;
    top: -40px;
`

export const AuthSection = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.isMobile ? "100%" : "50%"};
    height: ${props => props.isMobile ? "auto" : "100%"};
    padding: ${props => props.isMobile ? "0" : "0 40px 0 0" };
    box-sizing: border-box;
`

export const AuthForm = styled.form`
    width: 100%;
    text-align: center;
    margin: ${props => props.isMobile ? "40px auto 0" : "80px auto 0" };

    .MuiFormControlLabel-label {
        font-size: 10px;
        text-align: left;
    }
`
export const CenteredAuthForm = styled(AuthForm)`
    flex: 1 1 0px;
    width: 88%;
    max-width: 400px;
    text-align: center;
    margin: 40px auto 0;
    position: relative;
    
    .MuiFormControlLabel-label {
        font-size: 10px;
        text-align: left;
    }
`

export const AuthFormCentered = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`

export const Heading = styled.h1`
    font-size: 36px;
    font-weight: 600;
    margin: 0;
`

export const SubHeading = styled.h2`
    font-size: 18px;    
    font-weight: normal;
    margin: 0;
`

export const GoogleLoginButton = styled.div`
    > div {
        display: flex;
        justify-content: center;
    }
`

export const OrLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.backgroundColor};
`

export const Footer = styled.div`
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: ${props => props.flexEnd ? "flex-end" : "space-between"};
    align-items: center;
    max-width: 400px;
    width: 100%;


    a {
        cursor: pointer;
    }
`

 export const StepCount = styled.div`
    font-size: 12px;
    color: ${props => props.color};
 `

export const FullImage = styled.div`
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-image: ${props => `url('${props.url}')`};
`

export const AuthStepsContainer = styled.div`
    overflow: hidden;
`

const AuthSlider = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: ${props => `${props.count * 100}%`};
    transform: ${props => `translateX(-${100 / props.count * props.current}%)`};
    transition: transform 0.8s cubic-bezier(0.22, 1, 0.36, 1);
`

export const AuthSteps = styled(AuthSlider)`
    justify-content: space-around;
`

export const AuthStep = styled.div`
    width: 100%;
    max-width: 400px;
`

export const AuthStepButton = styled.div`
    margin-top: 40px;
`

export const AuthCarouselContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 50%;
    height: 100%;
`

export const AuthCarousel = styled(AuthSlider)`
    height: 100%;
`

export const AuthUpload = styled.div`
    background-color: #F2F2F2;
    padding: 34px;
    border: ${props => props.active ? "1px solid black" : "1px solid #f2f2f2"};
`

export const InitScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.whiteBk ? "#ffffff" : "#27C77E"};
    color: white;
    z-index: 10000;
`
