import { useState } from "react"
import { useSelector } from "react-redux"

import { Fab } from "@mui/material"
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'

import { formattedCurrency } from "@/utils"
import { selectSections, selectTopsheetExistingColumns, selectClientQuoted, selectAddons, selectFinancialTerm, selectInsurance, selectProject, selectBidUpdate } from "@/bid/bidSlice"
import { selectHasPermission } from "@/auth/authSlice"
import { PreviewContainer, PreviewBackground, PreviewContents, PrintPreviewContents, TopP, TopAddon, TopSpan, TopSheetSection, Image, Description, Signature, ExtraInfo, SenderNames, BidBills } from "@/bid/TopSheetPreviewComponents"

export const TopSheetPreview = ({printMode, viewOnly}) => {
    const bid = useSelector(selectBidUpdate)
    const project = useSelector(selectProject)
    const insurance = useSelector(selectInsurance)
    const financialTerm = useSelector(selectFinancialTerm)
    const columnSet = useSelector(selectTopsheetExistingColumns)
    const sections = useSelector(selectSections)
    const clientQuoted = useSelector(selectClientQuoted)
    const addons = useSelector(selectAddons)
    const hasBidApprovalFlow = useSelector(state => selectHasPermission(state, "bid_approval_flow"))
    
    const [zoomLevel, setZoomLevel] = useState(0)
    
    const StyledContents = printMode ? PrintPreviewContents : PreviewContents

    if (!bid || !bid.line_items_grouped){
        return null
    }

    return (
        <PreviewContainer >
            {!printMode && (
                <>
                    <PreviewBackground zoomLevel={zoomLevel} />

                    <Fab
                        size="small"
                        sx={{position: "absolute", bottom: 64, right: 16}}
                        onClick={() => setZoomLevel(zoomLevel-1)}
                        disabled={(zoomLevel == 0)}
                    >
                        <ZoomOutIcon />
                    </Fab>
                    <Fab
                        size="small"
                        sx={{position: "absolute", bottom: 16, right: 16}}
                        onClick={() => setZoomLevel(zoomLevel+1)}
                        disabled={(zoomLevel == 2)}
                    >
                        <ZoomInIcon />
                    </Fab>
                </>
            )}

            <StyledContents zoomLevel={zoomLevel} condensed={!bid.top_sheet_condensed}>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;600&display=swap" rel="stylesheet"></link>

                {hasBidApprovalFlow && bid.status == "Drafts" && ( 
                    <div style={{
                        margin: 0,
                        color: "black",
                        position: "absolute",
                        textAlign: "center",
                        pointerEvents: "none",
                    }}>
                        {
                            new Array(5).fill(0).map((_, index)=>{
                                return(
                                    <TopP key={index} size={12} style={{transform: "rotate(-30deg)", color: "#66666611", margin: 0, padding: 0}}>Draft</TopP>
                                )
                            })
                        }
                    </div> 
                )}

                <div style={{display: "flex", justifyContent: "space-between", alignItems: "start", marginBottom: bid.top_sheet_condensed ? "1em" : "4em"}}>
                    <Image image={project.legal_entity.logo} size={2} />
                    <div style={{textAlign: "right", color: "#aaa"}}>
                        <TopP size={0.5} style={{whiteSpace: "pre-line"}}>{project.legal_entity.name}</TopP>
                        <TopP size={0.5} style={{whiteSpace: "pre-line"}}>{project.legal_entity.address}</TopP>
                    </div>
                </div>
            
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
                    <div>
                        <TopP size={1.2} style={{paddingBottom: bid.top_sheet_condensed ? "0.5em" : "1em"}}>{project.name}</TopP>
                        <div style={{color: "#aaa"}}>
                            <TopP size={0.6}>Project Estimate</TopP>
                            <SenderNames condensed={bid.top_sheet_condensed} />
                            <TopP size={0.6}>{ new Date().toDateString() }</TopP>
                            <TopP size={0.6}>{`#${ project.docket }`}</TopP>
                            <ExtraInfo />
                        </div>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <TopP size={0.6} style={{fontWeight: 800, paddingTop: "1em", paddingBottom: "1em"}}>RECIPIENT</TopP>
                        <TopP size={0.6} >{project.client.name}</TopP>
                    </div>
                </div>
                <table>
                    <tbody>
                        {sections.map(section=>
                            <TopSheetSection
                                key={section}
                                id={section}
                                currency={project.currency}
                                showZero={bid.include_zero_lines}
                                showDecimal={bid.include_decimals}
                                columnSet={columnSet}
                                printMode={printMode}
                                viewOnly={viewOnly}
                                condensed={bid.top_sheet_condensed}
                            />
                        )}
                    </tbody>
                </table>
            
                <div style={{marginTop: bid.top_sheet_condensed ? "0.5em" : "2.5em"}}>
                    <TopAddon 
                        name={"Insurance"} 
                        amount={insurance} 
                        currency={project.currency} 
                        showZero={bid.include_zero_lines}
                        showDecimal={bid.include_decimals} 
                        condensed={bid.top_sheet_condensed}
                    />
                    {Object.values(addons).map((addon, index)=>
                        <TopAddon 
                            key={index} 
                            name={addon.name} 
                            amount={addon.quoted} 
                            currency={project.currency} 
                            showZero={bid.include_zero_lines}
                            showDecimal={bid.include_decimals} 
                            condensed={bid.top_sheet_condensed}
                        />
                    )}
                </div>

                <div style={{marginBottom: bid.top_sheet_condensed ? "1em" : "2.5em", fontWeight: 600, marginLeft: "50%", display: "flex", justifyContent: "space-between", padding: bid.top_sheet_condensed ? "0" : "0.5em 0", borderBottom: "1px #ddd solid"}}>
                    <TopSpan size={0.7}>Grand Total</TopSpan>
                    <TopSpan size={0.7}>{formattedCurrency(clientQuoted, project.currency, true, bid.include_decimals ? 2 : 0)}</TopSpan>
                </div>
                
                <Description title="Project Notes and Assumptions" text={bid.assumptions} show={bid.include_notes && bid.assumptions} condensed={bid.top_sheet_condensed} />
                <Description title="Bid includes" text={bid.inclusions} show={bid.include_notes && bid.inclusions} condensed={bid.top_sheet_condensed} />
                <Description title="Bid does not include" text={bid.exclusions} show={bid.include_notes && bid.exclusions} condensed={bid.top_sheet_condensed} />

                <Description title="Financial Terms" text={financialTerm?.description} show={bid.include_financial_terms} condensed={bid.top_sheet_condensed} />
                    
                <BidBills show={bid.include_bill_schedule} projectCurrency={project.currency} />

                <Description show={bid.include_code_of_conduct} title="Code of Conduct" condensed={bid.top_sheet_condensed} text={"We at Makers aim to conduct business with the highest standards of honest and ethical behavior. Our Code of Conduct can be found online at thecode.makers.to.  We assume all partners agree to the Code when working with Makers. If you have any concerns, please connect with your direct Makers Producer or Executive Producer."}/>
                <Description show={bid.include_covid_terms} title="COVID-19 Safety Costs" condensed={bid.top_sheet_condensed} text={"Additional personnel on set may require additional support costs, in addition to different testing needs. We will keep you updated to how these changes will affect the overall costs in this portion of the budget."}/>
                <Description show={bid.include_liability_waiver} title="Limitation Liability" condensed={bid.top_sheet_condensed} text={"Notwithstanding anything set forth in this estimate to the contrary, the parties acknowledge and agree that: (i) neither client nor Makers, nor any of their respective shareholders, directors, officers, employees and/or agents shall be held liable for indirect, incidental, consequential, special, punitive or exemplary damages or any loss of profit, loss of contracts, loss or damage to reputation and/or goodwill, and loss and corruption of data, arising in any manner from the activities, whether under contract, tort, or other cause of action, even if such party has been advised of the possibility of such damages, and (ii) Makers' total, aggregate liability for all claims arising in connection with this project shall not exceed the fees paid under this estimate. Claims for damages must be made by client within one (1) year of the incident to which they relate or be forever barred."}/>
            
                <div style={{display: "flex", width: "100%" , justifyContent: "space-between", margin: bid.top_sheet_condensed ? "1em 0 2em 0" : "2em 0 3em 0", flexWrap: "wrap"}}>
                    {
                        Array(bid.signer_count).fill().map((_, i)=>
                            <Signature key={i} printMode={printMode}/>
                        )
                    }
                </div>
            </StyledContents>
        </PreviewContainer>
    )
}
