import { createSlice, createSelector } from '@reduxjs/toolkit'
import { setAuthHeader } from "@/Api"
import { parseJwt } from '@/auth/authUtils'

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: null,
        tokenValid: true,
        accountId: null,
        personId: null,
        personProfileId: null,
        organizationId: null,
        organizations: [],
        isOrganizationInitializing: false,
        isBaseOrg: false,
        name: null,
        email: null,
        profileImage: null,
        roleIds: [],
        roles: [],
        permissions: [],
        subscription: null,
        isProfileComplete: false,
        isSuperAdmin: false,
    },
    reducers: {
        setToken: (state, action) => {
            const token = action.payload
            if (!token) {
                state.tokenValid = false
                return
            }

            const data = parseJwt(token)
            if (data == null) {
                state.tokenValid = false
                return
            }

            const tokenKeys = [
                "account_id",
                "person_id",
                "person_profile_id",
                "organization_id",
                "organizations",
                "is_organization_initializing",
                "name",
                "email",
                "profile_pic",
                "role_ids",
                "role_names",
                "permissions",
                "is_profile_complete",
                "subscription",
                "strategies",
            ]
            if (!tokenKeys.every(key => data.hasOwnProperty(key))) {
                state.tokenValid = false
                return
            }
            
            state.token = token
            state.tokenValid = true
            state.accountId = data.account_id
            state.personId = data.person_id
            state.personProfileId = data.person_profile_id
            state.organizationId = data.organization_id
            // Sort by active org first, paused orgs last, others inbetween
            state.organizations = data.organizations.sort((a, b) => a.id == data.organization_id ? -1 : b.id == data.organization_id ? 1 : a.paused - b.paused)
            state.isOrganizationInitializing = data.is_organization_initializing
            state.name = data.name
            state.email = data.email
            state.profileImage = data.profile_pic
            state.roleIds = data.role_ids
            state.roles = data.role_names
            state.permissions = data.permissions
            state.isProfileComplete = data.is_profile_complete
            state.subscription = data.subscription
            state.strategies = data.strategies
            state.isBaseOrg = data.subscription == "Template"
            state.isSuperAdmin = data.is_super_admin || false

            localStorage.setItem('jwtToken', token)
            setAuthHeader(token)
            
        },
        setProfileImage: (state, action) => {
            state.profileImage = action.payload
        },
        setInvitedPerson: (state, action) => {
            state.invitedPerson = action.payload
        },
        logout: (state) => {
            state.token = null
            state.accountId = null
            state.personId = null
            state.personProfileId = null
            state.organizationId = null
            state.organizations = []
            state.isOrganizationInitializing = false
            state.name = null
            state.email = null
            state.profileImage = null
            state.roleIds = []
            state.roles = []
            state.permissions = []
            state.strategies = []
            state.subscription = null
            state.isProfileComplete = false
            state.isBaseOrg = false
            state.isSuperAdmin = false

            localStorage.removeItem("jwtToken")
            setAuthHeader("")
        },
    }
})

export const {
    setToken, setProfileImage, logout, setInvitedPerson
} = authSlice.actions


export const selectIsTokenInvalid = (state) => !state.auth.tokenValid
export const selectIsLoggedIn = (state) => state.auth.token != null
export const selectIsOrganizationInitializing = (state) => state.auth.isOrganizationInitializing
export const selectIsProfileComplete = (state) => state.auth.isProfileComplete
export const selectPermissions = (state) => state.auth.permissions
export const selectHasPermission = (state, permission) => state.auth.permissions.includes(permission)
export const selectHasRole = (state, role) => state.auth.roles.includes(role)
export const selectStrategies = (state) => state.auth.strategies
export const selectHasStrategy = (state, strategy) => state.auth.strategies.includes(strategy)
export const selectToken = (state) => state.auth.token
export const selectAccountId = (state) => state.auth.accountId
export const selectPersonProfileId = (state) => state.auth.personProfileId
export const selectPersonId = (state) => state.auth.personId
export const selectName = (state) => state.auth.name
export const selectProfileImage = (state) => state.auth.profileImage
export const selectIsBaseOrg = (state) => state.auth.isBaseOrg
export const selectOrganizationId = (state) => state.auth.organizationId
export const selectActiveOrganization = (state) => state.auth.organizations.find(org => org.id == state.auth.organizationId)
export const selectOrganizations = (state) => state.auth.organizations
export const selectInvitedPerson = (state) => state.invitedPerson
export const selectIsSuperAdmin = (state) => state.auth.isSuperAdmin


export const selectNavTokenContext = createSelector(
    [selectPermissions, selectStrategies, selectIsSuperAdmin],
    (permissions, strategies, isSuperAdmin) => {
        return {
            permissions,
            strategies,
            isSuperAdmin,
        }
    }
)

export default authSlice.reducer
