import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTheme } from "@emotion/react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { request } from "@/Api";
import { ShadedBox, Title, NumericFormatCustom } from "@/common/StyledComponents";
import { selectBidUpdate, selectClientCurrency, selectAddonIds, selectAddon, updateAddon, updateDetails } from "@/bid/bidSlice";
import { getCurrencySymbol, formattedCurrency } from "@/utils";

const CustomGridItem = (props) => {
    const { children, isinput, theme } = props
    return(
        <Grid item {...props}>
            <Box
                sx={{padding: (isinput ? null : "0px 16px 0px 16px"), background: theme.palette.background.default, borderRadius: 4}}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                { children }
            </Box>
        </Grid>
    )
}

const AddonTextField = ({bidId, addonId, readOnly, sendSocketMessage}) => {
    const [addonLocal, setAddonLocal] = useState()
    const [isRenaming, setIsRenaming] = useState(false)
    const [isWritingAmount, setIsWritingAmount] = useState(false)
    const dispatch = useDispatch()
    const addon = useSelector(state=>selectAddon(state, addonId))
    const clientCurrency = useSelector(selectClientCurrency)

    useEffect(()=>{
        setAddonLocal(addon)
    }, [addon])

    const handleAmountInput = (event) => {
        let value = event.target.value
        value = addon.type == "percentage" ? value / 100 : value
        setAddonLocal({...addonLocal, "amount": value})
    }

    const handleNameInput = (event) => {
        setAddonLocal({...addonLocal, "name": event.target.value})
    }

    const handleAddonBlur = (field) => () => {
        setIsRenaming(false)
        setIsWritingAmount(false)
        if(addon[field] == addonLocal[field]){
            return
        }
        request.put(`/bids/${bidId}/addon/${addon.id}`, addonLocal)
        .then((response)=>{
            sendSocketMessage({type: "updateAddon", addonId: addon.id})
            dispatch(updateAddon(response.data))
        })
    }

    if (!addonLocal){
        return
    }

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                {isRenaming ? 
                    <TextField
                        autoFocus
                        sx={{paddingTop: 1.4, paddingBottom: 1.4, minWidth: 50, width: `${addonLocal.name.length * 1.1}ch`}}
                        value={addonLocal.name}
                        onInput={handleNameInput}
                        onBlur={handleAddonBlur("name")}
                        onKeyDown={(e) => {e.key!="Enter" || handleAddonBlur(e)}}
                        size="small"
                        variant="standard"
                        disabled={readOnly}
                    />:
                    <p style={{cursor: "pointer", color: addonLocal.name ? "auto" : "gray"}} onClick={()=>{setIsRenaming(true)}}>
                        {addonLocal.name || "Click to name this addon"}
                    </p>
                }
            </Grid>

            <Grid item flexGrow={1} textAlign="right">
                {isWritingAmount ? 
                    <TextField
                        autoFocus
                        value={addonLocal.type=="percentage" ? (addonLocal.amount * 100).toFixed() : addonLocal.amount}
                        onBlur={handleAddonBlur("amount")}
                        onInput={handleAmountInput}
                        onKeyDown={(e) => {e.key!="Enter" || handleAddonBlur(e)}}
                        size="small"
                        variant="standard"
                        InputProps={{
                            endAdornment: addonLocal.type=="percentage" ? "%" : "",
                        }}
                        inputProps={{style: {textAlign: "right", padding: 0}}}
                        disabled={readOnly}
                    />:
                    <p style={{cursor: "pointer"}} onClick={()=>{setIsWritingAmount(true)}}>
                        {
                            addon.type=="percentage" ? 
                            `${(addon.amount * 100).toFixed()}%` : 
                            formattedCurrency(addonLocal.amount, clientCurrency)
                        }
                    </p>
                }
            </Grid>
        </Grid>
    )
}

export default function BidAddOns(props){
    const { bidId, readOnly, sendSocketMessage } = props
    const [localBid, setLocalBid] = useState({})
    const [insuranceMenuOpen, setInsuranceMenuOpen] = useState(false)
    const [insuranceType, setInsuranceType] = useState(null)
    const insuranceExpandIconButton = useRef()
    const theme = useTheme()
    const bid = useSelector(selectBidUpdate)
    const addons = useSelector(selectAddonIds)
    const clientCurrency = useSelector(selectClientCurrency)
    const dispatch = useDispatch()

    const updateBid = (bidPayload) => {
        dispatch(updateDetails({...bidPayload}))
        request.patch(`/bids/${bidId}`, bidPayload)
        .then(()=>{
            sendSocketMessage({type: "update", bidId: bidId})
        })
    }

    const handleAddonBlur = (field) => (event) => {
        let value = event.target.value.replace(/,/g, '');
        updateBid({...localBid, [field]: value})
    }

    const handleInsuranceClear = (event) => {
        updateBid({...localBid, "custom_insurance": null})
    }

    useEffect(()=>{
        if (!bid.id) {
            return
        }

        setLocalBid({...bid, top_sheet_sender_ids: bid.top_sheet_senders.map((sender)=>{return sender.id})})
        setInsuranceType(bid.custom_insurance != null ? "custom" : "calculated")
    },[bid])

    return (
        <ShadedBox backgroundColor={theme.palette.background.paper}>
            <Title margin="0 0 12px 0" bold>Add-ons</Title>
            <Grid container spacing={2}>
                <CustomGridItem md={6} theme={theme}>
                    <p>Insurance</p>
                    <div style={{display: "flex"}}>
                        { insuranceType == "custom" ?
                        <TextField
                            value={bid.custom_insurance}
                            onBlur={handleAddonBlur("custom_insurance")}
                            onKeyDown={(e) => {e.key!="Enter" || handleAddonBlur(e)}}
                            size="small"
                            variant="standard"
                            InputProps={{
                                inputComponent: NumericFormatCustom,
                                startAdornment: getCurrencySymbol(clientCurrency)
                            }}
                            inputProps={{style: {textAlign: "right", padding: 0}}}
                            disabled={readOnly}
                        /> : <p style={{margin: 0}}>{`${(bid.insurance_rate * 100).toFixed(1)}%`}</p> }
                        {readOnly ? null : (
                            <IconButton onClick={()=>{setInsuranceMenuOpen(true)}} ref={insuranceExpandIconButton} sx={{padding:0}}>
                                <ExpandMoreIcon/>
                            </IconButton>
                        )}
                        <Menu
                            MenuListProps={{sx: {backgroundColor: 'white'}}}
                            anchorEl={insuranceExpandIconButton.current}
                            open={insuranceMenuOpen}
                            onClose={()=>{setInsuranceMenuOpen(false)}}
                            elevation={0}
                            anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
                            transformOrigin={{vertical: 'top',horizontal: 'left',}}
                        >
                                <MenuItem
                                    sx={{"color": "black", '&:hover': {backgroundColor: 'lightgray'}}}
                                    onClick={()=>{setInsuranceType("calculated"); handleInsuranceClear(); setInsuranceMenuOpen(false)}}
                                >
                                    {`${(bid.insurance_rate * 100).toFixed(1)}%`}
                                </MenuItem>
                                <Divider sx={{width: "100%", background: "gray"}}/>
                                <MenuItem
                                    sx={{"color": "black", '&:hover': {backgroundColor: 'lightgray'}}}
                                    onClick={()=>{setInsuranceType("custom"); setInsuranceMenuOpen(false)}}
                                >
                                    custom amount
                                </MenuItem>
                        </Menu>
                    </div>
                </CustomGridItem>

                {addons ? addons.map(addonId=>
                    <CustomGridItem md={6} theme={theme} key={addonId}>
                        <AddonTextField 
                            bidId={bidId}
                            addonId={addonId}
                            readOnly={readOnly}
                            sendSocketMessage={sendSocketMessage}
                        />
                    </CustomGridItem>  
                ) : null}
            </Grid>
        </ShadedBox>
    )
}
